import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import ActionBookingsDetailsController from 'uplisting-frontend/pods/action/bookings/details/controller';

export default abstract class SidebarBookingsDetailsRoute extends BaseRoute {
  abstract parentRoute: string;

  model() {
    return this.modelFor(this.parentRoute);
  }

  setupController(
    controller: ActionBookingsDetailsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });
  }
}
