import type AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';

export enum AlertStatus {
  pending = 'pending',
  resolved = 'resolved',
}

export enum AlertType {
  airbnbUserSuspension = 'airbnb_official_connection_user_suspension',
}

export interface IAlertSchema {
  title: string;
  description: string;
  status: AlertStatus;
  type: AlertType;
  createdAt: Date;

  airbnbOfficialConnections?: AirbnbOfficialConnectionModel[];
}
