import Model, { attr, hasMany } from '@ember-data/model';
import type AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';
import {
  AlertStatus,
  AlertType,
  type IAlertSchema,
} from 'uplisting-frontend/models/schemas';

export default class AlertModel extends Model implements IAlertSchema {
  @attr('string') title!: string;
  @attr('string') description!: string;
  @attr('string') status!: AlertStatus;
  @attr('string') type!: AlertType;
  @attr('date') createdAt!: Date;

  @hasMany('airbnb-official-connection', { async: false, inverse: null })
  airbnbOfficialConnections?: AirbnbOfficialConnectionModel[];

  public get isResolved(): boolean {
    return this.status === AlertStatus.resolved;
  }

  public get isSubmitting(): boolean {
    return !!this.isSaving;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    alert: AlertModel;
  }
}
